<template>
  <div>
    <b-form-checkbox class="mb-3" v-model="selected" :disabled="isDisabled"
                     :name="name" :text-field="name" :value="true" :unchecked-value="false">
      {{ text }}
    </b-form-checkbox>
    {{ handleSelection }}
  </div>
</template>

<script>
import FilterConstant from "@/components/Filter/FilterConstant.vue";

export default {
    name: "SidebarCheckbox",
    mixins: [FilterConstant],
    data() {
        return {selected: false};
    },
    props: {
        name: {
            type: String,
            required: true,
            default: () => 'none'
        },
        text: {
            type: String,
            required: true,
            default: () => 'none'
        },
        setSelection: {
            type: Function,
            required: true,
            default: () => {
            }
        },
        isDisabled: {
            type: Boolean,
            required: true,
            default: () => false
        },
        eventSelect: {
            type: String,
            required: false,
            default: () => 'SELECT_CHOICE'
        },
        eventReset: {
            type: String,
            required: false,
            default: () => 'RESET_CHOICE'
        },
        defaultValue: {
            type: Boolean,
            required: false,
            default: () => null
        }
    },
    mounted() {
        this.$root.$on(this.eventReset, () => {
            this.resetChoices();
        });
        this.$root.$on(this.eventSelect, (selection) => {
            this.selected = selection;
        });
        if (this.defaultValue !== null) {
            this.selected = this.defaultValue;
        }
    },
    computed: {
        handleSelection: function () {
            return this.setSelection(this.selected);
        }
    },
    methods: {
        resetChoices() {
            this.selected = false;
        }
    }
};
</script>