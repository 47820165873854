<template>
  <div>
    <b-form-radio-group
        v-model="selected"
        :options="options"
        class="mb-3"
        stacked
        :name="name"
        :disabled="isDisabled"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
    >
    </b-form-radio-group>
    {{ handleSelection}}
  </div>
</template>

<script>
import FilterConstant from '@/components/Filter/FilterConstant';

export default {
    name: 'SidebarRadio',
    mixins: [FilterConstant],
    data() {
        return {
            selected: ''
        };
    },
    props: {
        name: {
            type: String,
            required: true,
            default:() => 'none'
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        setSelection: {
            type: Function,
            required: true,
            default: () => {}
        },
        isDisabled: {
            type: Boolean,
            required: true,
            default: () => false
        },
        eventSelect: {
            type: String,
            required: false,
            default: () => 'SELECT_CHOICE'
        },
        eventReset: {
            type: String,
            required: false,
            default: () => 'RESET_CHOICE'
        },
        defaultValue: {
            type: Number,
            required: false,
            default: () => null
        }
    },
    mounted() {
        this.$root.$on(this.eventReset, ()=> {
            this.resetChoices();
        });
        this.$root.$on(this.eventSelect, (selection)=> {
            this.selected = selection;
        });
        if (this.defaultValue!== null) {
            this.selected = this.defaultValue;
        }
    },
    computed: {
        handleSelection: function () {
            return this.setSelection(this.selected);
        }
    },
    methods: {
        resetChoices() {
            this.selected = '';
        }
    }
};
</script>
