<template>
  <b-row>
    <b-col>
      <b-form-datepicker
          id="schedule"
          v-model="fromDateSelected"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          calendar-width="50%"
          class="export-date-picker"
          close-button
          locale="nl"
          reset-button
          size="sm"
          today-button
      ></b-form-datepicker>
    </b-col>
    <b-col>
      <b-form-timepicker
          v-model="fromTimeSelected"
          size="sm"
      ></b-form-timepicker>
    </b-col>
    {{formDate}}
  </b-row>
</template>

<script>
export default {
    name: 'SidebarDateTimePicker',
    data() {
        return {
            fromDateSelected: null,
            fromTimeSelected: null,
        };
    },
    computed: {
        formDate: function () {
            return this.setNewDate(`${this.fromDateSelected}T${this.fromTimeSelected}`);
        }
    },
    props: {
        setNewDate: {
            type: Function,
            required: true,
            default: () => console.log('DateTime')
        }
    }
};
</script>
