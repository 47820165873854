<template>
  <div>
    <b-row>
      <b-col>
        <b-form-textarea
            id="textarea-state"
            v-model="text"
            :state="isDisabled || (text.length >= min && text.length <= max)"
            placeholder="Enter a reason"
            :disabled="isDisabled"
            rows="3"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col>({{ text.length }}/{{ max }} characters)</b-col>
    </b-row>
    {{ updateMotivation }}
  </div>
</template>

<script>
export default {
    name: 'SidebarTextArea',
    data() {
        return {
            text: '',
        };
    },
    props: {
        setMotivation: {
            type: Function,
            required: true,
            default: () => {
            },
        },
        max: {
            type: Number,
            required: true,
            default: () => 300,
        },
        min: {
            type: Number,
            required: true,
            default: () => 10,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    computed: {
        updateMotivation: function () {
            return this.setMotivation(this.text);
        }
    }
};
</script>
