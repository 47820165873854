<template>
  <div class="worker-details" style="height: 90%;">
    <SidebarHeader
        :handle-sidebar="handleSidebar"
        :is-back-button-active="true"
        :is-menu-enabled="false"
        :title="$t('admin.transactions.manage.title')"
    />
    <div v-if="isApprove" class="worker-profile basic-padding container-for-transaction">
      <div v-if="isApproveLoading" class="text-center">
        <b-spinner :label="$t('admin.transactions.manage.approval')"></b-spinner>
        <p>{{ $t('admin.transactions.manage.approval') }}</p>
      </div>
      <div v-if="!isApproveLoading" class="text-center">
        <img :alt="$t('admin.transactions.manage.approved')" src="@/assets/images/icon-transaction-approved.svg"/>
        <p>{{ $t('admin.transactions.manage.approved') }}</p>
      </div>
    </div>
    <div v-if="!isApprove" class="worker-profile basic-padding">
      <b-row class="header">
        <h4 class="subtitle-question">{{ $t('admin.common.warning') }}</h4>
      </b-row>
      <b-row class="header deactivate-user-warning-content">
        <b-icon-exclamation-triangle-fill variant="danger"></b-icon-exclamation-triangle-fill>
        <p class="no-padding left-padding">{{ $t('admin.transactions.refuse-transaction.warning') }}</p>
      </b-row>
      <div class="worker-profile">
        <b-row class="header">
          <h4 class="subtitle-question">{{ $t('admin.transactions.refuse-transaction.question') }}</h4>
        </b-row>
        <b-row class="header">
          <b-form-group>
              <textarea v-model="refusalReason"
                        :placeholder="$t('admin.transactions.refuse-transaction.third-reason-spec')"
                        class="text-area-size"></textarea>
          </b-form-group>
        </b-row>
      </div>
      <div class="header fixed-bottom button-wrapper">
        <b-button :disabled="!isButtonEnabled()" block variant="danger" @click="refusePayment(paymentType, pid)">
          {{ $t('admin.transactions.detail.refuse-payment') }}
        </b-button>
        <b-button block variant="outline-primary" @click="eraseSelection()">
          {{ $t('admin.common.cancel') }}
        </b-button>
        <hr/>
      </div>
    </div>
  </div>
</template>
<style scoped>
.container-for-transaction {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-area-padding {
  padding-left: 22px;
  padding-top: 10px;
}

.text-area-size {
  width: 320px;
  height: 150px;
}
</style>
<script>

import ToasterUtils from '@/utils/ToasterUtils.vue';
import ApiUtils from '@/utils/ApiUtils.vue';
import FilterConstant from '@/components/Filter/FilterConstant.vue';
import SidebarHeader from "@/components/Layout/Header/SidebarHeader.vue";

export default {
    name: 'ManageTransaction',
    components: {SidebarHeader},
    mixins: [ToasterUtils, ApiUtils, FilterConstant],
    data() {
        return {
            isLoading: false,
            selected: [],
            refusalReason: '',
        };
    },
    props: {
        paymentType: {
            type: String,
            default: () => '',
        },
        pid: {
            type: Number,
            default: () => 0,
        },
        isApprove: {
            type: Boolean,
            default: () => true,
        },
        isApproveLoading: {
            type: Boolean,
            default: () => true,
        },
        table: {
            type: Object,
            default: () => {
            },
        },
        isPis: {
            type: Boolean,
            default: () => {
            },
        },
        isWal: {
            type: Boolean,
            default: () => {
            },
        },
        isWalc: {
            type: Boolean,
            default: () => {
            },
        },
        isPayout: {
            type: Boolean,
            default: () => {
            },
        },
    },
    methods: {
        eraseSelection() {
            this.selected = [];
            this.refusalReason = '';
        },
        isButtonEnabled() {
            return this.refusalReason !== '';
        },
        async refusePayment(paymentType, pid) {
            this.isLoading = true;

            try {
                await this.setHeader();
                let response = {};
                if (this.isPis) {
                    // PIS
                    response = await this.$api.refusePisPayment(pid, this.refusalReason);
                } else if (this.isPayout && this.isWalc) {
                    // WALC OUT
                    response = await this.$api.refuseWalcOutTransaction(pid, this.refusalReason);
                } else if (this.isPayout && this.isWal) {
                    // WAL OUT
                    response = await this.$api.refuseWalOutTransaction(pid, this.refusalReason);
                } else if (!this.isPayout && this.isWalc) {
                    // WALC IN
                    response = await this.$api.refuseWalcTransaction(pid, this.refusalReason);
                } else if (!this.isPayout && this.isWal) {
                    // WAL IN
                    response = await this.$api.refuseWalTransaction(pid, this.refusalReason);
                }
                this.responseStatus = response.data.status;
                this.$root.$emit(this.TRANSACTION_APPROVE_REFUSE_RESPONSE, response.data.status);
                this.isLoading = false;
                this.makeToast(response.status === 200, `Payment ${pid} Refused successfully`);
            } catch (e) {
                console.error(e);
                this.makeToast(false, `Payment ${pid} Refusal operation, unsuccessful`);
            }
            this.handleSidebar();
            this.table.refresh();
            this.isLoading = false;
            this.refusalReason = '';
        },
        handleSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-refuse');
        }
    }
};
</script>
