<template>
  <b-row>
    <b-col cols="11">
      <h4 :class="titleClassName">{{ title }}</h4>
      <br/>
    </b-col>
    <b-col v-if="isCollapsible" cols="1" @click="handleCollapse">
      <img v-if="!isOpen" alt="Arrow" class="user-dropdown-image" src="@/assets/images/dropdown-arrow.svg">
      <img v-if="isOpen" alt="Arrow" class="user-dropup-image" src="@/assets/images/dropup-arrow.svg">
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'SideTitleSidebarComponent',
    data(){
        return{
            isOpen: true
        };
    },
    props: {
        title: {
            type: String,
            default: () => {
            }
        },
        isCollapsible: {
            type: Boolean,
            default: () => (false)
        },
        handleCollapse: {
            type: Function,
            default: () => {
            }
        },
        collapseId: {
            type: String,
            default: () => ("")
        },
        titleClassName : {
            type: String,
            required: false,
            default: () => {
                return "";
            }
        }
    },
    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (collapseId === this.collapseId) {
                this.isOpen = isJustShown;
            }
        });
    }
};
</script>

<style scoped>
.little-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
</style>
