<template>
  <b-row>
    <b-col cols="6">
      <h6>{{ title }}</h6>
    </b-col>
    <b-col cols="6" class="align-to-right" style="direction: rtl">
      <div :class="status.className">
        <p v-if="isStatusText && !actionText">{{ status.name }}</p>
        <p v-if="!isStatusText && !actionText">{{ $t(status.name) }}</p>
        <p v-if="!isStatusText && actionText">{{ actionText }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'SidebarEntryStatus',
    props: {
        title: {
            type: String,
            default: () => 'No Key'
        },
        status: {
            type: Object,
            default: () => 'No value'
        },
        isStatusText: {
            type: Boolean,
            default: () => false
        },
        actionText: {
            type: String,
            default: () => ""
        },

    }
};
</script>

<style scoped>
.payroll-status-open-container {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  background: #F0F0F0;
  max-width: 110%;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0;
  object-fit: cover;
}

.payroll-status-closed-container {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  background: #EDF7E1;
  max-width: 110%;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0;
  object-fit: cover;
}

.payroll-status-open-container > p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #666666;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 6px;
}

.payroll-status-closed-container > p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #528217;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 6px;
}
</style>
