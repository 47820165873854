<template>
  <b-row @click="copyValue">
    <b-col><h6>{{ title }}</h6></b-col>
    <b-col v-if="isImage" class="align-to-right">
      <img :src="value" :alt="title"/>
    </b-col>
    <b-col v-if="!isClickable && !isImage" class="align-to-right">
      <p v-if="!isCode">{{ value ? value : 'N/A' }}</p>
      <code v-if="isCode">{{ value ? value : 'N/A' }}</code>
    </b-col>
    <b-col v-if="isClickable && !isImage" class="align-to-right">
      <a class="clickable" @click="onClick">{{ value ? value : 'N/A' }}</a>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'SidebarEntry',
    props: {
        title: {
            type: String,
            default: () => 'No Key'
        },
        value: {
            type: [String, Number],
            default: () => 'No value'
        },
        isClickable: {
            type: Boolean,
            default: () => false
        },
        onClick: {
            type: Function,
            default: () => {
            }
        },
        isCode: {
            type: Boolean,
            default: () => false
        },
        isImage: {
            type: Boolean,
            default: () => false

        }
    },
    methods : {
        copyValue() {
            navigator.clipboard.writeText(this.value);
        }
    }
};
</script>
